import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TabMenu } from 'primeng/tabmenu';


interface Tabs {
  Id: number;
  Name: string;
  Route: string;
}

@Component({
  selector: 'app-e-navigation-tabs',
  templateUrl: './e-navigation-tabs.component.html',
  styleUrl: './e-navigation-tabs.component.scss'
})
export class ENavigationTabsComponent {
  @Input() links!: MenuItem[];

}
